<template>
  <div
    class="comment-card-block"
    :class="(comment.is_out != 1) ? 'comment-card-block-out' : ''"
    @click="openComment()"
    :id="comment.id"
    :ref="comment.id"
  >
    <div class="comment-card-title">
      <div class="comment-card-title-span-user">
        <span>{{comment.to_user_name}}</span>
      </div>
      <div class="comment-card-title-span-date">
        <span>{{getdate(comment.crdate)}}</span>
      </div>
    </div>
    <div class="comment-card-body">
      {{comment.description}}
    </div>
    <div class="comment-card-statuses">
      <label v-if="comment.is_vizited == 0" class="label-unvizited"/>
      <label v-else class="label-vizited"/>
    </div>
  </div>
</template>

<script>
import {bus} from "@/router/bus";

export default {
  name: "CommentCard.vue",
  
  props: {
    comment: {
      type: Object
    }
  },
  
  data: () => ({
    clicked: false,
  }),
  
  methods: {
    getdate(value) {
      return (new Date(value)).toLocaleString("ru");
    },
    
    openComment(){
      this.$emit("open_comment", {
        comment: this.comment
      });
    },
    
    setVizited() {
      const uri = this.$api + this.$methods.setCommentVizited.url
      const token = this.$_getsetting('token')
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
      };

      const params = {
        id: this.document.id
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error != 0) {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        } else {
          bus.$emit("recalccommentscount")
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
.comment-card-block{
  font-size: 14px;
  border-radius: 10px;
  /*border: 1px solid #e6eae9;*/
  margin-bottom: 10px;
  margin-left: 0;
  background-color: #e6eae9;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 0.81%;
  padding-bottom: 0.81%;
  cursor: pointer;
  
  &:hover {
    background: #D4D4D4;
    //cursor: pointer;
  }
}

.comment-card-block-out{
  margin-left: 25%;
  background-color: #BEE08E;
  
  &:hover {
    background: #B0CF84;
    //cursor: pointer;
  }
}

.comment-card-title{
  width: 98%;
  text-align: left;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-style: italic;
}
.comment-card-body{
  font-size: 14px;
  text-align: left;
  margin: 0 10px 0 10px;
  padding-top: 0.81%;
  max-height: 30px;
  white-space: nowrap; /* Текст не переносится */
  overflow: hidden; /* Обрезаем всё за пределами блока */
  text-overflow: ellipsis;
}
.comment-card-statuses{
  text-align: right;
  margin-right: 1.62%;
}
.comment-card-title-span-user{
  width: 50%;
  margin-left: 1.62%;
}
.comment-card-title-span-date{
  width: 50%;
  text-align: right;
  margin-right: 1.62%;
}
.label-vizited:before{
  content: '\2713\2713';
  display: inline-block;
  color: green;
  font-size: 16px;
  line-height: 16px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.label-vizited:before{
  -webkit-transform: scale(1);
  transform: scale(1);
}
.label-unvizited:before{
  content: '\2713';
  display: inline-block;
  color: green;
  font-size: 16px;
  line-height: 16px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.label-unvizited:before{
  -webkit-transform: scale(1);
  transform: scale(1);
}
</style>

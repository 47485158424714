<script>
import {bus} from '../../../router/bus';
import { request_list_grid_sets } from "../../jsons/gridsets";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";
import "splitpanes/dist/splitpanes.css";
import CVTBDateInput from "../../common/CVTBDateInput.vue";

import {
  DxDataGrid,
  DxScrolling,
  DxStateStoring,
  DxSummary,
  DxTotalItem,
  DxHeaderFilter,
  DxSearchPanel,
  DxColumnChooser,
  DxExport,
  DxPager,
  DxPaging,
} from "devextreme-vue/data-grid";

export default {
  name: "ListCopy",
  
  components: {
    DxDataGrid,
    DxScrolling,
    DxStateStoring,
    DxLoadPanel,
    DxSummary,
    DxTotalItem,
    DxHeaderFilter,
    DxSearchPanel,
    DxColumnChooser,
    DxPager,
    DxPaging,
    CVTBDateInput,
    DxExport,
  },
  
  data: () => ({
    component_name: "CVRequestList",
    dataSource: [],
    archiveRates: [],
    analogRates: [],
    identical_rate_key: null,
    indexOfIdenticalRate: null,
    dataDocuments: {},
    columns: request_list_grid_sets,
    showHeaderFilter: true,
    autoExpandAll: true,
    currentMode: "widget",
    sync: true,
    showRight: false,
    showOptions: false,
    rowAlternationEnabled: true,
    filterValue: "",
    dataGridRefName: "CVRequestListDataGrid",
    dataGridRefName2: "CVRequestListDataArchGrid",
    popupVisible: false,
    loadingVisible: false,
    selected_status: null,
    filterBuilderPopupPosition: {
      of: window,
      at: "top",
      my: "top",
      offset: { y: 10 },
    },
    
    send_rate_request_data: [],
    showOrderRequestForm: false,
    
    on_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    
    dates: [],
    D: new Date().setMonth(new Date().getMonth() -6),
    date_from: '',
    date_to: new Date(),
    
    elements: {
      date_from: {
        caption: "С",
        name: "date_from",
        disabled: false,
        alt: "",
        type: "date"
      },
      
      date_to: {
        caption: "По",
        name: "date_to",
        disabled: false,
        alt: "",
        type: "date"
      },
    },
    
  }),
  
  watch:{
    selected_status(value) {
      if (value == null) {
        this.$refs[this.dataGridRefName].instance.clearFilter();
        this.filterValue = '';
      }
    }
  },
  
  mounted() {
    this.loadingVisible = true
    this.dataToString()
    this.clearStatusFilter()
    this.getArchOrdersList()
    this.hideStateIdColumn()
    
    bus.$on("show_send_request", (args) => {
      this.send_rate_request_data = args
      this.is_showvars = false
      this.showOrderRequestForm = true
    });
  },
  
  created() {
    loadMessages(ruMessages);
    locale(navigator.language);
  },
  
  methods: {
    hideStateIdColumn() {
      this.columns.push({"dataField":"state_id", "caption":"ID статуса", "dataType":"number", "allow-editing": false, visible: false},)
    },
    
    ord_id_text(data){
      return data.value.toLocaleString()
    },
    
    dataToString() {
      let currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 6);
      this.date_from = currentDate.toISOString().substr(0, 10);
      
      let out_date = new Date(this.on_date)
      out_date.setDate(out_date.getDate());
      this.date_to = out_date.toISOString().substr(0, 10);
    },
    
    clearStatusFilter() {
      if (this.$refs[this.dataGridRefName] != undefined) {
        const dataGrid = this.$refs[this.dataGridRefName].instance;
        dataGrid.clearFilter()
        this.filterValue = ''
        this.selected_status = null
      }
    },
    
    clearArchStatusFilter() {
      if (this.$refs[this.dataGridRefName2] != undefined) {
        const dataGrid = this.$refs[this.dataGridRefName2].instance;
        dataGrid.clearFilter()
        this.filterValue = ''
        this.selected_status = null
      }
    },
    
    onRowClick(order_rate) {
      this.$emit('onRowClick', order_rate)
    },
    
    addStatusFilter(id) {
      if (this.$refs[this.dataGridRefName] != undefined) {
        const dataGrid = this.$refs[this.dataGridRefName].instance;
        switch (id) {
          case '0' : {
            dataGrid.clearFilter();
            this.filterValue = '';
          }
            break
          default: {
            this.filterValue =  [['state_id', '=', id]];
          }
            break
        }
      }
    },
    
    getArchOrdersList() {
      this.loadingVisible = true
      this.dataSource = []
      this.archiveRates = []

      const uri = this.$api + this.$methods.getArchOrdersList.url
      const token = this.$_getsetting('token')
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
      };

      const params = {
        client_id: this.$_getsetting('client_id'),
        date_from: this.date_from,
        date_to: this.date_to
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0) {
          this.archiveRates = JSON.parse(data.message);
          this.loadingVisible = false
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    }
  },
};
</script>

<template>
  <div class="list-container">
    <div class="request-list__pane__container pa-6">
      <v-btn
        v-if="archiveRates.length > 0"
        class="request-list__pane__container-tabs-clear-arch-filter"
        color="#EF8159"
        text
        elevation="0"
        dense
        x-small
        @click="clearArchStatusFilter()"
      >
        Очистить фильтры
      </v-btn>
      
      <form
        class="request-list__pane__container-arch"
        @submit.prevent="getArchOrdersList"
      >
        <div class="request-list__pane__container-arch-title">Поставки выгруженные в периоде</div>
        
        <CVTBDateInput
          class="request-list__pane__container-arch-input pa-10"
          @input="date_from = $event"
          @selected="date_from = $event.value"
          :element="elements.date_from"
          :val="date_from"
          :dense="true"
        />
        
        <CVTBDateInput
          class="request-list__pane__container-arch-input"
          @input="date_to= $event"
          @selected="date_to = $event.value"
          :element="elements.date_to"
          :val="date_to"
          :dense="true"
        />
        
        <v-btn
          height="40px"
          type="submit"
          color="var(--main-bg-color)"
          dark
          class="request-list__pane__container-arch-btn">
          Показать
        </v-btn>
      </form>
    </div>
    
    <div class="request-list__pane__archive__ttl" v-if="archiveRates === false || archiveRates === null">
      Поставки за данный период не найдены
    </div>
    
    <div class="request-list__pane__archive" v-if="archiveRates.length > 0">
      
      <div class="request-list__pane__container-grid">
        
        <DxDataGrid
          width="100%"
          key-expr="ord_id"
          :hoverStateEnabled="true"
          id="mt-archive-form"
          :columns="columns"
          :ref="dataGridRefName2"
          :selection="{ mode: 'single' }"
          :data-source="archiveRates"
          :word-wrap-enabled="false"
          :allow-column-reordering="true"
          :show-borders="true"
          :focused-row-enabled="true"
          :allow-column-resizing="true"
          :column-resizing-mode="currentMode"
          :row-max-height="10"
          :column-auto-width="true"
          :filter-value="filterValue"
          :filter-sync-enabled="sync"
          :row-alternation-enabled="rowAlternationEnabled"
          @row-dbl-click="onRowClick"
        >
          
          <DxLoadPanel
            :visible.sync="loadingVisible"
            :show-indicator="true"
            :show-pane="true"
            :shading="true"
            :close-on-outside-click="false"
          />
          
          <DxScrolling mode="standard"/>
          
          <DxPaging :page-size="1000"/>
          
          <DxPager
            :visible="false"
          />
          
          <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="mt-archive-form"
          />
          
          <DxHeaderFilter
            :visible="showHeaderFilter"
          />
          
          <DxExport
            :enabled="true"
            :allow-export-selected-data="true"
            class="mt-4"
            file-name="mt-archive-form"
          />
          
          <DxColumnChooser class="mt-2" :enabled="true"/>
          
          <DxSearchPanel
            id="mt-archive-search"
            :visible="true"
            :width="240"
            placeholder="Найти..."
          />
          
          <DxSummary>
            <DxTotalItem
              :customize-text="ord_id_text"
              column="ord_id"
              summary-type="count"
            />
            
            <DxTotalItem
              :customize-text="ord_id_text"
              column="amount_sum"
              summary-type="sum"
              value-format="#,##0.00"
            />
            
            <DxTotalItem
              :customize-text="ord_id_text"
              column="pay_sum"
              summary-type="sum"
              value-format="#,##0.00"
            />
            
            <DxTotalItem
              :customize-text="ord_id_text"
              column="dolg"
              summary-type="sum"
              value-format="#,##0.00"
            />
          
          </DxSummary>
        </DxDataGrid>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../../node_modules/devextreme/dist/css/dx.common.css';
@import '../../../../node_modules/devextreme/dist/css/dx.material.orange.light.css';

.dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter::after,
.dx-datagrid .dx-column-indicators .dx-sort.dx-header-filter::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0px;
}

body::-webkit-scrollbar {
  width: 12px;               /* ширина всей полосы прокрутки */
}

body::-webkit-scrollbar-track {
  background: orange;        /* цвет зоны отслеживания */
}

body::-webkit-scrollbar-thumb {
  border-radius: 20px;       /* округлось бегунка */
}

.list-container {
  z-index: 3020;
}

.request-list {
  border-radius: 10px;
  margin-top: -1px;
  margin-left: 1%;
  margin-right: 1%;
  //overflow: hidden !important;
  overflow-x: visible;
  z-index: 3020;
  
  @media (max-width: 750px) {
    display: none;
  }
  
  &__card-dialog {
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 20px 0;
    
    &-similar {
      color: var(--main-orange);
      font-weight: 700;
      margin: 0 0 -10px 25px;
    }
    
    &-analog {
      color: black;
      font-weight: 600;
      margin-left: 25px;
      font-size: 18px;
    }
  }
  
  &__pane {
    padding: 0;
    border-radius: 5px;
    background-color: #FFFFFF;
    border-width: 3px 1px 1px 1px;
    border-style: solid;
    border-color:  #e8e8e8;
    height: 88vh;
    white-space: nowrap;
    overflow-x: auto !important;
    left: -5px;
    box-shadow: 0px 3px 8px rgba(9, 30, 66, 0.16), 0px 0px 1px rgba(9, 30, 66, 0.31);
    
    @media (min-width: 1800px) {
      height: 92vh;
    }
    
    //@media (min-width: 1800px) {
    //  height: calc(102% - 80px);
    //}
    
    &__container {
      display: flex;
      background-color: #fff;
      margin: 0;
      white-space: nowrap;
      overflow-x: auto !important;
      width: 100%;
      
      &-arch {
        display: flex;
        z-index: 200;
        margin-top: -16px;
        
        &-title {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          margin: 5px 10px 0 -10px;
          letter-spacing: 0.01em;
          color: #000000;
        }
        
        &-input {
          width: 200px !important;
          margin: 0 20px !important;
          height: 40px !important;
        }
        
        &-btn {
          text-transform: none;
          margin: 0px 0 0 10px;
          height: 50px;
          letter-spacing: 0.7px;
        }
      }
      
      &-tabs {
        margin-left: 10px;
        width: 90%;
        
        &-archive {
          position: absolute;
          padding-bottom: 2px;
          height: 50px;
          right: 260px;
          z-index: 200;
        }
        
        &-finance {
          margin: 0;
          width: 100%;
        }
        
        &-select {
          position: absolute;
          right: 0px;
          margin-top: 2px;
          width: 250px !important;
        }
        
        &-clear-status {
          position: absolute;
          top: 5px;
          width: 35px !important;
          text-transform: none;
          right: 255px;
          margin-top: 2px;
        }
        
        &-clear-filter, &-clear-arch-filter {
          position: absolute;
          z-index: 200;
          text-transform: none;
          left: 0;
          padding: 16px 8px !important;
          font-weight: 600;
          letter-spacing: 0.07em;
          font-size: 14px;
          line-height: 20px;
          border: solid 1px #EF8159;
          color: #EF8159;
        }
        
        &-clear-filter {
          top: 25px;
        }
        
        &-clear-arch-filter {
          top: 60px;
        }
      }
      
      &__tab {
        color: var(--bg-dark) !important;
        //text-transform: none;
        height: 35px;
        margin: 5px 3px -7px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 8px 15px;
        border: 1px solid var(--main-bg-color);
        border-radius: 4px;
        
        &-active {
          background: var(--bg-dark);
          color: #FFFFFF !important;
          border: 1px solid #FFFFFF;
        }
      }
      
      &-divider {
        width: 120%;
        position: relative;
        height: 2px;
        left: -20px;
        background-color: #DFDDDD;
        margin: -8px 0 5px -10%;
      }
      
      &-grid {
        width: 100%;
        margin: 10px 0 0 ;
        height: calc(100vh - 180px);
        overflow: visible;
        
        
        &__menu-list {
          border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        }
      }
    }
    
    &__archive {
      margin-top: -60px;
      display: flex;
      background: none;
      white-space: nowrap;
      height: 80%;
      overflow-x: auto !important;
      width: 100%;
      
      &__ttl {
        margin: 10px 0 0 20px;
        font-size: 20px;
      }
    }
  }
}

.request-list-mobile {
  display: none;
  padding: 20px 10px 4px;
  
  &:not(:first-child) {
    padding-top: 3px;
  }
  
  @media (max-width: 750px) {
    display: block;
  }
}

#mt-archive-search {
  top: -20px;
}

.copy-btn {
  margin-left: 174px;
}

#mt-request-list, #mt-archive-form {
  height: 95%;
  background: rebeccapurple;
  white-space: nowrap !important;
  overflow-x: auto !important;
}

</style>

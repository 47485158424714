<script>
export default {
  name: 'CompanyRequisite',
  
  props: {
    payersData: {
      type: [Array, Object],
      required: true
    },

    activeCompanyKey: Number,
    
    isCompanyInfo: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<template>
  <div class="requisite">
    <div class="requisite__company-name">
      <h3>{{ payersData.fullname }}</h3>
    </div>
    <div class="row requisite__row">
      <div v-if="payersData.accounts_data[0] != null">
        <div class="requisite__row-data">
          <h2>ИНН</h2>
          <h3>{{payersData.inn}}</h3>
        </div>
        
        <div class="requisite__row-data" >
          <h2>БИК</h2>
          <h3>{{payersData.accounts_data[0].bik}}</h3>
        </div>
      </div>
      
      <div v-else-if="isCompanyInfo" class="requisite__row-data ml-6 requisite__info-block">
        <p class="requisite__info-block-description">Необходимо подписать договор для возможности <br> оформления заявки ЛК</p>
        <router-link
          :to="'/AddCompanyData?inn=1&key=' + activeCompanyKey"
          class="requisite__info-block-link"
        >
        Подробнее
        </router-link>
      </div>
    </div>
  
    <div v-if="payersData.accounts_data[0] != null">
      <h2>Наименование банка</h2>
      <h3>{{payersData.accounts_data[0].fullname}}</h3>
    </div>
    
    <div v-if="payersData.accounts_data[0] != null">
      <h2>Номер счёта</h2>
      <h3>{{ payersData.accounts_data[0].csaccount}}</h3>
    </div>
    
  </div>
</template>

<style lang="scss" scoped>
.requisite {
  &__company-name {
    max-width: calc(100% - 100px);
  }
  
  &__row-data {
    margin-left: 12px;
  }
  
  &__link {
    color: var(--bg-dark);
    text-decoration: none;
    line-height: 10px;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    top: 10px;
    
    > span {
      text-decoration: underline;
      
      &:hover {
        color: var(--main-bg-color);
      }
    }
  }
  
  &__description {
    margin-top: 10px;
    line-height: 18px;
    font-weight: 300;
    font-size: 14px;
  }

  &__row{
    gap: 0 12px;
  }

  &__info-block{
    display: flex;
    align-items: center;
    gap: 0 20px;

    background-color: var(--red-10);
    padding: 8px 12px; 
    border-radius: 4px;
    margin-bottom: 16px;
    margin-left: 12px !important;

    &-description{
      @include font-description-semibold-1;
    }

    &-link{
      @include font-description-semibold-0;
      color: var(--red-100);
      text-decoration: none;
      transition: opacity .2s ease-in-out;

      @include media-hover('&:active'){
        opacity: .8;
      }
    }
  }
}
h2 {
    @include font-subtitle-regular;
    
     line-height: 19px;
     letter-spacing: 0.01em;
     margin-top: 10px;
   }

h3 {
  @include font-body-1;
  
  font-weight: 600;
  letter-spacing: 0.01em;
  margin-bottom: 25px;
}


</style>

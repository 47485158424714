<script>
import CommentCard from "@/components/common/CommentCard.vue";
import MessageForm from "./MessageForm.vue";
import CommentsChatFooter from "../../../common/CommentsChatFooter.vue";

import {bus} from "@/router/bus";

export default {
  name: "CommentTabNew.vue",
  components: {
    CommentCard,
    MessageForm,
    CommentsChatFooter
  },
  
  props: {
    comments: {
      type: Array
    },
    
    ord_id: {
      type: [Number, String]
    },
    
    chatFooter: {
      type: Boolean,
      default: true
    }
  },
  
  data: () =>({
    showCommentText: false,
    showMessageForm: false,
    document: {},
    is_new: true,
  }),
  
  mounted(){
    bus.$on("open_comment", (data) => {
      this.openComment(data.comment)
    });
    
    this.scrollToLastComment()
  },
  
  methods: {
    scrollToLastComment() {
      let lastComment = this.comments.slice(-1);
      const el = document.getElementById(`${lastComment[0].id}`);
      el.scrollIntoView();
    },
    
    createnewcomment() {
      this.is_new = true
      this.document = []
      this.showMessageForm = true
    },

    openComment(data){
      this.is_new = false
      this.document = data.comment
      this.showCommentText = true
    },
  
    sendClient(data) {
      const uri = this.$api + this.$methods.setClientRequestComment.url
      const token = this.$_getsetting('token')
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
      };

      const params = {
        obj_id: this.ord_id,
        uuser_id: +this.$_getsetting('user_id'),
        uuser: this.$_getsetting('fio'),
        description: data
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0) {
          this.$emit('close', 0)
        
          bus.$emit("recalccommentscount")
        
          bus.$emit("show_notify", {
            color: "success",
            notifytext: data.message
          });
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    getcommentsids(){
      let ids = ''
      for (let i = 0; i < this.comments.length; i++){
        if (i == 0)
          ids = this.comments[i].id
        else
          ids = ids + ',' + this.comments[i].id
      }
      return ids
    },
    
    setallconnentsvizited() {
      const uri = this.$api + this.$methods.setMultiCommentVizited.url
      const token = this.$_getsetting('token')
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
      };

      const params = {
        ids: this.getcommentsids()
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error != 0) {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    }
  },
}
</script>

<template>
  <div>
    <div class="request-title" v-if="chatFooter">
      <div class="comments-action">
        <div class="btn-left">
          <v-btn
            class="mx-2 btm-brdr-right btn-height"
            text
            dark
            small
            color="primary"
            @click="setallconnentsvizited"
          >
            Пометить все как прочитанные
            <v-icon dark>
              mdi-message-processing
            </v-icon>
          </v-btn>

        </div>
        <div class="btn-right">

<!--          <v-btn-->
<!--            class="mx-2 btm-brdr-right btn-height"-->
<!--            text-->
<!--            dark-->
<!--            small-->
<!--            color="primary"-->
<!--            @click="createnewcomment()"-->
<!--          >-->
<!--            Написать сообщение-->
<!--            <v-icon dark>-->
<!--              mdi-message-text-outline-->
<!--            </v-icon>-->
<!--          </v-btn>-->

        </div>
      </div>
    </div>

    <v-dialog
      class="mt-editor-form"
      v-model="showMessageForm"
      persistent
      transition="dialog-bottom-transition"
      width="60%" height="60%"
    >

      <MessageForm
        commentType="client"
        :document ="document"
        :is_new="is_new"
        :ord_id="this.ord_id"
        @close="showMessageForm = false, $emit('updateComments')"
      />
    </v-dialog>
    
    
    <v-dialog
      class="mt-editor-form"
      v-model="showCommentText"
      persistent
      @click:outside="showCommentText = false"
      transition="dialog-bottom-transition"
      width="60%" height="60%"
    >
      <v-card class="mt-editor-form">
        <v-btn
          icon
          class="mt-editor-form__title my-0"
          @click="showCommentText = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        
      <div class="comment-text-container">
        <pre class="comment-dialog-text">{{document.full_text}}</pre>
      </div>
    
        <v-divider></v-divider>
    
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="var(--main-bg-color)"
            text
            @click="showCommentText = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
        
      </v-card>

    </v-dialog>

    <div class="comment-container">
      <CommentCard
        @open_comment="openComment($event)"
        class="comment-card"
        v-for="item in comments"
        :key="item.id"
        :comment="item"
      />
    </div>
    <CommentsChatFooter
      v-if="chatFooter"
      @sendMessage="sendClient($event)"
      class="comment-container-footer"
    />
  </div>
</template>

<style scoped lang="scss">
.comment-container{
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(75vh - 100px);
  padding-top: 1.62%;
  margin-bottom: 100px;
  padding-bottom: 1.62%;
  
  &:last-child {
    height: 80vh !important;
  }
}

.comment-card {
  width: 75%;
}
.comment-container-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}

.request-title {
  flex-direction: row;
  width: 100%;
  margin: auto;
  line-height: 40px;
}
.btn-right{
  text-align: right;
  margin: 0.4%;
  float: right;
}
.btn-left{
  text-align: left;
  margin: 0.4%;
  float: left;
}
.btn-height{
  min-height: 2.5vw;
}
#mt-order-history{
  height: calc(100vh - 250px);
}
.comments-action{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.mt-editor-form{
  background-color: #fff;
  //overflow-x: hidden;
  /*overflow: hidden !important;*/
}

.mt-editor-form__title {
  position: sticky;
  top: 15px;
  left: 95%;
}

.v-dialog{
  background-color: #ffffff;
  width: 60% !important;
}

.comment-dialog-text {
  font-size: 18px;
  padding: 20px 40px;
  overflow-y: scroll;
  max-width: 92%;
  white-space: pre-wrap;
  font-family: 'Montserrat', sans-serif;
}
</style>

<script>
export default {
  name: "ServicesTab",
  
  props: {
    order_id: {
      type: [String, Number],
      required: true
    }
  },
  
  data: () => ({
    expences_data: [],
    is_main_cost: 0,
    is_added_cost: 0,
  
    additional: {
      detention: [],
      port_storage: [],
      port_additional: [],
      station_storage: [],
      station_additional: [],
    },
    
    settingsFormShow: false,
    detention_comment: '',
    port_storage_comment: '',
    station_storage_comment:'',
    schedule: [],

    dataArr: [],
  }),
  
  watch: {
    order_id: function () {
      this.getOrderExpensesById()
    },
  },
  
  mounted() {
    this.getOrderExpensesById()
  },
  
  methods: {
    getOrderExpensesById() {
      this.expences_data = []
      const uri = this.$api + this.$methods.getAdditionalExpensesRateByOrdId.url
      const token = this.$_getsetting('token')
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
      };

      const params = {
        ord_id: this.order_id
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.message != 0) {
          this.expences_data = JSON.parse(data.message)
          this.getAdditionalHeaders(this.expences_data.detention)
          this.getAdditionalHeaders(this.expences_data.port_storage)
          this.getAdditionalHeaders(this.expences_data.station_storage)
          this.getDataArray()
        } 
      })
    },
  
    getAdditionalHeaders(arr){
      for (let i = 0; i < arr.length; i++){
        if (arr[i].comment.length != 0){
          switch(arr[i].service_id){
            case 1039: this.detention_comment = arr[i].comment.toLowerCase(); break
            case 1040: this.port_storage_comment = arr[i].comment.toLowerCase(); break
            case 1041: this.station_storage_comment = arr[i].comment.toLowerCase(); break
            default: break
          }
          if (arr[i].comment != 0) break
        }
      }
    },
  
    formatItem(item){
      if (item.days_from != 0){
        if (item.days_to != 999)
          return 'c ' + item.days_from + ' по ' + item.days_to + ' день, всего дней ' + (parseInt(item.days_to - item.days_from) )
        else
          return 'cвыше ' + item.days_from + ' дней'
      }
      else
        return ' по ' + item.days_to + ' день, всего дней ' + (parseInt(item.days_to - item.days_from))
    },
  
    getnumber(value){
      return parseFloat(value).toLocaleString('ru-RU')
    },

    getDataArray(){
      Object.values(this.expences_data).forEach(arr => {
        if(arr.length > 0){
          this.dataArr.push(arr)
        }
      })
    },
  },
}
</script>

<template>
  <div class="expenses-tab" v-if="dataArr.length > 0" >
    <div v-if="expences_data.local_additional.length > 0">
      <h3 class="block-header">Терминальные расходы в порту отправления:</h3>
      <table class="rate-card-detail-table">
        <tr class="rate-card-detail-row-bottom-border" v-for="item in expences_data.local_additional" :key="item.id">
          <td class="rate-card-detail-row-station-caption"><h4>{{ item.comment }}</h4></td>
          <td class="rate-card-detail-row-bottom-border">{{ formatItem(item) }}</td>
          <td class="td-right rate-card-detail-row-bottom-border">{{ getnumber(item.price) }}&nbsp;
            <span v-if="item.currency_id == '840'">&#36;</span>
            <span v-else-if="item.currency_id == '978'">&#8364;</span>
            <span v-else>&#8381;</span></td>
        </tr>
      </table>
    </div>
  
    <div v-if="expences_data.detention.length > 0">
      <h3 class="block-header">Сверхнормативное использование контейнера:</h3>
      <table class="rate-card-detail-table">
        <tr class="rate-card-detail-row-bottom-border" v-for="item in expences_data.detention" :key="item.id">
          <td class="rate-card-detail-row-station-caption"><h4>{{ item.comment }}</h4></td>
          <td class="rate-card-detail-row-bottom-border">{{ formatItem(item) }}</td>
          <td class="td-right rate-card-detail-row-bottom-border">{{ getnumber(item.price) }}&nbsp;
            <span v-if="item.currency_id == '840'">&#36;</span>
            <span v-else-if="item.currency_id == '978'">&#8364;</span>
            <span v-else>&#8381;</span></td>
        </tr>
      </table>
    </div>
    
    <div  v-if="expences_data.port_storage.length > 0">
      <h3 class="block-header">Хранение в порту прибытия:</h3>
      <table class="rate-card-detail-table">
        <tr class="rate-card-detail-row-bottom-border" v-for="item in expences_data.port_storage" :key="item.id">
          <td class="rate-card-detail-row-station-caption"><h4>{{ item.comment }}</h4></td>
          <td class="rate-card-detail-row-bottom-border">{{ formatItem(item) }}</td>
          <td class="td-right rate-card-detail-row-bottom-border">{{ getnumber(item.price) }}&nbsp;
            <span v-if="item.currency_id == '840'">&#36;</span>
            <span v-else>&#8381;</span></td>
        </tr>
      </table>
    </div>
    
    <div v-if="expences_data.port_additional.length > 0">
      <h3 class="block-header">Дополнительные услуги в порту прибытия:</h3>
      <table class="rate-card-detail-table">
        <tr class="rate-card-detail-row-bottom-border" v-for="item in expences_data.port_additional" :key="item.id">
          <td class="rate-card-detail-row-bottom-border">{{ item.obj_name }}</td>
          <td class="td-right rate-card-detail-row-bottom-border">{{ getnumber(item.price) }}&nbsp;
            <span v-if="item.currency_id == '840'">&#36;</span>
            <span v-else>&#8381;</span></td>
        </tr>
      </table>
    </div>
  
    <div v-if="expences_data.station_storage.length > 0">
      <h3 class="block-header">Хранение на станции прибытия:</h3>
      <table class="rate-card-detail-table">
        <tr
          class="rate-card-detail-row-bottom-border"
          v-for="item in expences_data.station_storage"
          :key="item.id"
        >
          <td class="rate-card-detail-row-station-caption"><h4>{{ item.comment }}</h4></td>
          <td class="rate-card-detail-row-bottom-border">{{ formatItem(item) }}</td>
          <td class="td-right rate-card-detail-row-bottom-border">{{ getnumber(item.price) }}&nbsp;
            <span v-if="item.currency_id == '840'">&#36;</span>
            <span v-else>&#8381;</span></td>
        </tr>
      </table>
    </div>
  </div>
  <div class="not-found-msg" v-else>Доп. расходы не найдены.</div>
</template>

<style scoped>
.block-header {
  width: 95%;
  margin: 10px 0 10px 2.5%;
}

.expenses-tab {
  height: 80vh;
  overflow-y: scroll;
}

.rate-card-detail-table {
  width: 100%;
  font-size: 10pt;
  border: 1px solid #e6eae9;
  background: #FFFFFF;
  border-radius: 10px;
  margin: auto;
  padding: 2% 2.5%;
}
.td-right {
  text-align: right;
  padding-right: 1%;
}
.td-center {
  text-align: center;
}
.tr-total {
  background-color: #fff;
  font-weight: bold;
}

.rate-card-detail-row-bottom-border{
  border-bottom: 1px solid #e6eae9;
  text-align: left;
}

.rate-card-detail-row-station-caption{
  width: 50%
}

.not-found-msg{
  text-align: center;
  margin-top: 100px;
  color: rgba(102,102,102,.87);
}
</style>

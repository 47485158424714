<script>
import {requested_documents_grid} from "../../../jsons/finances-tab";
import {bus} from "../../../../router/bus";
import {
  DxDataGrid,
  DxScrolling,
  DxColumnFixing,
  DxStateStoring,
  DxSummary,
  // DxTotalItem,
  DxHeaderFilter,
  DxColumnChooser,
  DxExport,
  DxPager,
  DxPaging,
} from "devextreme-vue/data-grid";

import AppLoader from "../../../ui/AppLoader.vue";

export default {
  name: "FinanceDocumentList",
  
  components: {
    DxDataGrid,
    DxScrolling,
    DxColumnFixing,
    DxStateStoring,
    DxSummary,
    // DxTotalItem,
    DxHeaderFilter,
    DxColumnChooser,
    DxPager,
    DxPaging,
    DxExport,
    AppLoader
  },
  
  data() {
    return {
      requestList: [],
      gridNames: requested_documents_grid,
      dataGridRefName: "CVDocumentListDataGrid",
      rowAlternationEnabled: true,
      showHeaderFilter: true,
      filterValue: "",
      currentMode: "widget",
      sync: true,
      loadingVisible: false,
      showLoader: false
    }
  },
  
  mounted() {
    this.getClientRequestsList()
  },
  
  methods: {
    getClientRequestsList() {
      this.showLoader = true
      const uri = this.$api + this.$methods.getClientRequestsList.url
      const token = this.$_getsetting('token')
      const headers = {
          headers: {
            "Authorization": `Bearer ${token}`
          }
      };
      const params = {
        client_id: this.$_getsetting('client_id'),
      }

      this.$postapi(uri, params, headers)
      .then(data => {
          this.requestList = JSON.parse(data.message)
          this.parseNumberData();
      }).catch(err => {
          bus.$emit("show_notify", {
          color: "error",
          notifytext: err.message
        });
      }).finally(() => {
          this.showLoader = false;

      });
    },
    
  parseNumberData() {
    for (let i = 0; i < this.requestList.length; i++) {
      const objects = this.requestList[i].objects
      this.requestList[i].cont_numbers = objects.map(obj => obj.cont_num)
      this.requestList[i].ord_numbers = objects.map(obj => obj.ord_num)
    }
  }
  }
}
</script>

<template>
  <div class="table-finance-loader" v-if="showLoader">
    <AppLoader :size="80"/>
  </div>
  <div class="request-form"  v-else>
    
    <div class="invoice__list" >
 
      <DxDataGrid
       
        width="100%"
        key-expr="id"
        :hoverStateEnabled="true"
        id="mt-document-list"
        :columns="gridNames"
        :ref="dataGridRefName"
        :selection="{ mode: 'single' }"
        :data-source="requestList"
        :word-wrap-enabled="true"
        :allow-column-reordering="true"
        :show-borders="true"
        :focused-row-enabled="true"
        :allow-column-resizing="true"
        :column-resizing-mode="currentMode"
        :row-max-height="10"
        :column-auto-width="true"
        :filter-value="filterValue"
        :filter-sync-enabled="sync"
        :row-alternation-enabled="rowAlternationEnabled"
      >
        <DxScrolling mode="standard"/>
      
        <DxPaging :page-size="1000"/>
      
        <DxPager
          :visible="false"
        />
      
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="mt-document-list"
        />
      
        <DxHeaderFilter
          :visible="showHeaderFilter"
        />
      
        <DxExport
          :enabled="true"
          :allow-export-selected-data="true"
          class="mt-4"
          file-name="mt-document-list"
        />
      
        <DxColumnFixing :enabled="true"/>
      
        <DxColumnChooser class="mt-2" :enabled="true"/>
      
        <DxColumnFixing :enabled="true"/>
        <DxSummary>
<!--          <DxTotalItem-->
<!--            :customize-text="ord_id_text"-->
<!--            column="id"-->
<!--            summary-type="count"-->
<!--          />-->
<!--        -->
      
        </DxSummary>
      </DxDataGrid>
    </div>
  </div>
</template>

<style scoped lang="scss">
.document-list {
  height: 85vh;
  display: flex;
  background-color: #fff;
  overflow-x: auto !important;
  width: 100%;
}

.table-finance-loader{
  position: relative;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  margin-top: 150px !important;

}
</style>
